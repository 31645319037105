<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.required"
                      maxlength="255"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Alias"
                      v-model="data.alias"
                      maxlength="20"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Red de Proveedores*"
                      v-model="data.provider_group_id"
                      :rules="rules.required"
                      :items="provider_groups"
                      item-value="id"
                      :item-text="(item) => item.provider_group"
                      :loading="provider_groups_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Red de Médicos*"
                      v-model="data.doctor_group_id"
                      :rules="rules.required"
                      :items="doctor_groups"
                      item-value="id"
                      :item-text="(item) => item.doctor_group"
                      :loading="doctor_groups_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Concentrador*"
                      v-model="data.contractor_brand_id"
                      :items="contractor_brands"
                      item-value="id"
                      :item-text="(item) => item.contractor_brand"
                      :loading="contractor_brands_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Formato migración"
                      v-model="data.contractor_insured_format_id"
                      :items="contractor_insured_formats"
                      item-value="id"
                      :item-text="(v) => v.name"
                      :loading="contractor_insured_formats_ldg"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'Fiscal'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Razón social"
                      v-model="data.legal_name"
                      type="text"
                      dense
                      counter
                      maxlength="100"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="RFC*"
                      v-model="data.code_tax"
                      type="text"
                      dense
                      counter
                      maxlength="13"
                      :rules="rules.code_tax_sat_opt"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Código postal"
                      v-model="data.legal_zip"
                      type="text"
                      dense
                      counter
                      maxlength="5"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Régimen"
                      v-model="data.fiscal_regime_id"
                      :items="fiscal_regimes"
                      item-value="id"
                      :item-text="(v) => v.fiscal_regime"
                      :loading="fiscal_regimes_ldg"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Uso de CFDI"
                      v-model="data.fiscal_use_id"
                      :items="fiscal_uses"
                      item-value="id"
                      :item-text="(v) => v.fiscal_use"
                      :loading="fiscal_uses_ldg"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Método de pago"
                      v-model="data.fiscal_method_payment_id"
                      :items="fiscal_method_payments"
                      item-value="id"
                      :item-text="(v) => v.fiscal_method_payment"
                      :loading="fiscal_method_payments_ldg"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="
                        'Forma de pago: ' + (data.fiscal_is_ppd ? 'PPD' : 'PUE')
                      "
                      v-model="data.fiscal_is_ppd"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONFIGURACIÓN'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Polizario'"
                      v-model="data.insured_list"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Credencial'"
                      v-model="data.credential"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Carta exprés'"
                      v-model="data.express_letter"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Consultas'"
                      v-model="data.consultation"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Telemedicina'"
                      v-model="data.telemedicine"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Seguimiento médico'"
                      v-model="data.medical_monitoring"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Consolidado'"
                      v-model="data.consolidated"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Verificación 2 pasos'"
                      v-model="data.mfa"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'INFO visualizar servicios'"
                      v-model="data.external"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.external" cols="12" xs="12" md="3">
                    <v-text-field
                      label="Cantidad de servicios"
                      v-model="data.external_items"
                      type="number"
                      max="30000"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'INFO verificar código'"
                      v-model="data.verify_code"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Reembolso'"
                      v-model="data.refund"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Programación de cirugía'"
                      v-model="data.surgery_schedule"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Urgencias'"
                      v-model="data.urgency"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Aperturas'"
                      v-model="data.opening"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" class="pb-0">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Alta sin carta definitiva'"
                      v-model="data.dicharged_without_last_letter"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONDICIONES INGRESOS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Factor CPT"
                      v-model="data.cpt_factor"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Tope máx. suma asegurada"
                      v-model="data.max_assured"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Monto carta autorización exprés"
                      v-model="data.amount_express"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-select
                      label="Tipo de deducible*"
                      v-model="data.type_deductible"
                      :rules="rules.required"
                      :items="type_deductibles"
                      item-value="id"
                      :item-text="(item) => item.name"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Monto de deducible"
                      v-model="data.amount_deductible"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-select
                      label="Tipo de coaseguro*"
                      v-model="data.type_coinsurance"
                      :rules="rules.required"
                      :items="type_coinsurances"
                      item-value="id"
                      :item-text="(item) => item.name"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Porcentaje de coaseguro %"
                      v-model="data.percentage_coinsurance"
                      :rules="rules.text3"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Coaseguro HM %"
                      v-model="data.fee_coinsurance"
                      :rules="rules.text3"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Tope de Deducible y Coaseguro"
                      v-model="data.max_coinsurance"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-select
                      label="Revisiones de cartas*"
                      v-model="data.letters_review"
                      :rules="rules.required"
                      :items="letters_reviews"
                      item-value="id"
                      :item-text="(item) => item.name"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      class="mt-0 pt-0"
                      :label="'Deducibles anuales'"
                      v-model="data.annual_deductible"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      rows="1"
                      label="Leyendas"
                      v-model="data.caption"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2
                  class="text-caption"
                  v-text="'CONDICIONES SERVICIOS CONSULTAS'"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Monto de deducible"
                      v-model="data.rs_deducible_amount"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Coaseguro %"
                      v-model="data.rs_coinsurance"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Tope de Deducible y Coaseguro"
                      v-model="data.rs_coinsurance_amount"
                      type="number"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <v-text-field
                      label="Leyenda"
                      v-model="data.rs_caption"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2
                  class="text-caption"
                  v-text="'CONDICIONES SERVICIOS PROVEEDORES'"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" v-for="(v, i) in data.letters" :key="i">
                    <v-row v-if="v.active" dense>
                      <v-col cols="12" xs="12" md="3">
                        <v-autocomplete
                          :label="`Tipo de proveedor* (${i + 1})`"
                          v-model="v.provider_type_id"
                          :rules="rules.required"
                          :items="provider_types"
                          item-value="id"
                          :item-text="(v) => v.provider_type"
                          :loading="provider_types_loading"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-text-field
                          label="Monto de deducible"
                          v-model="v.deducible_amount"
                          type="number"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-text-field
                          label="Coaseguro %"
                          v-model="v.coinsurance"
                          type="number"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-text-field
                          label="Tope de Deducible y Coaseguro"
                          v-model="v.coinsurance_amount"
                          type="number"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="2">
                        <v-text-field
                          label="Tope máximo"
                          v-model="v.assured_amount"
                          type="number"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="2"
                        v-if="v.provider_type_id === 1"
                      >
                        <v-text-field
                          label="Días de expiración"
                          v-model="v.expiration_days"
                          type="number"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        :md="v.provider_type_id === 1 ? 9 : 11"
                      >
                        <v-text-field
                          label="Leyenda"
                          v-model="v.caption"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="letterRemove(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="`Eliminar día de recepción (${i + 1})`"
                          />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="letterAdd"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'PORCENTAJES POR ENTIDADES'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    v-for="(contractor_entity, i) in data.contractor_entities"
                    :key="i"
                  >
                    <v-row v-if="contractor_entity.active" dense>
                      <v-col cols="12" xs="12" md="5">
                        <v-autocomplete
                          :label="`Entidad (${i + 1})`"
                          v-model="contractor_entity.entity_id"
                          :rules="rules.required"
                          :items="entities"
                          item-value="id"
                          :item-text="(v) => v.entity"
                          :loading="entities_ldg"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="5">
                        <v-text-field
                          label="Porcentaje %"
                          v-model="contractor_entity.percent"
                          type="number"
                          min="0"
                          max="99"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="2"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="entityDlt(i)"
                            >
                              <v-icon medium> mdi-close </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar entidad (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="entityAdd"
                      color="warning"
                      x-small
                      block
                      dark
                    >
                      <v-icon left> mdi-plus </v-icon>
                      Agregar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'USUARIO SISTEMA'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :color="data.user.active ? 'warning' : 'success'"
                        icon
                        @click.prevent="data.user.active = !data.user.active"
                      >
                        <v-icon
                          v-text="
                            `mdi-account${data.user.active ? '-off' : ''}`
                          "
                        />
                      </v-btn>
                    </template>
                    <span
                      v-text="data.user.active ? 'Desactivar' : 'Activar'"
                    />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text v-if="data.user.active">
                <v-row dense>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Correo electrónico*"
                      v-model="data.user.email"
                      :rules="rules.email"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "contratantes",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: null,
        name: "",
        insured_list: false,
        credential: false,
        express_letter: false,
        consultation: false,
        medicine: false,
        lab: false,
        telemedicine: false,
        hospitalization: false,
        provider_group_id: null,
        doctor_group_id: null,
        cpt_factor: 1,
        max_assured: 0,
        amount_express: 0,
        type_deductible: 1,
        amount_deductible: 0,
        percentage_coinsurance: "0",
        type_coinsurance: 1,
        amount_coinsurance: 0,
        fee_coinsurance: "0",
        max_coinsurance: 0,
        caption: "",
        annual_deductible: 0,
        letters_review: 1,
        letters: [],
        rs_assured_amount: "0",
        medical_monitoring: false,
        consolidated: false,
        contractor_brand_id: null,
        user: {
          id: null,
          active: false,
        },
        alias: "",
        external: false,
        external_items: "15",
        refund: false,
        surgery_schedule: false,
        urgency: false,
        opening: false,
        dicharged_without_last_letter: false,
        verify_code: true,
        contractor_entities: [],
        legal_name: "",
        code_tax: "",
        legal_zip: null,
        fiscal_regime_id: null,
        fiscal_use_id: null,
        fiscal_method_payment_id: null,
        fiscal_is_ppd: true,
        contractor_insured_format_id: null,
      },
      rules: {},
      provider_groups: [],
      provider_groups_loading: true,
      doctor_groups: [],
      doctor_groups_loading: true,
      type_deductibles: [
        {
          id: 1,
          name: "Deducible",
        },
        {
          id: 2,
          name: "Por padecimiento inicial",
        },
        {
          id: 3,
          name: "Por enfermedad",
        },
      ],
      type_coinsurances: [
        {
          id: 1,
          name: "Coaseguro",
        },
        {
          id: 2,
          name: "Por enfermedad",
        },
      ],
      letters_reviews: [
        {
          id: 1,
          name: "1",
        },
        {
          id: 2,
          name: "2",
        },
        {
          id: 3,
          name: "3",
        },
      ],
      provider_types: [],
      provider_types_loading: false,
      contractor_brands: [],
      contractor_brands_loading: true,
      entities: [],
      entities_ldg: true,
      fiscal_regimes: [],
      fiscal_regimes_ldg: true,
      fiscal_uses: [],
      fiscal_uses_ldg: true,
      fiscal_method_payments: [],
      fiscal_method_payments_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      contractor_insured_formats: [],
      contractor_insured_formats_ldg: true,
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              let obj = Object.assign({}, this.data);
              obj.user = JSON.stringify(obj.user);

              storeUpdate(
                "contractors",
                this.login.token,
                this.id,
                obj,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".view"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(response.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    letterAdd() {
      this.data.letters.push({
        id: null,
        active: 1,
        service_type_id: null,
        amount_deductible: "",
        coinsurance: "",
        max_coinsurance: "",
        max_assured: "",
        expiration_days: "0",
      });
    },
    letterRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el tipo de proveedor (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.letters[i].id === null)
              this.data.letters.splice(i, 1);
            else this.data.letters[i].active = false;
          }
        });
    },
    entityAdd() {
      this.data.contractor_entities.push({
        id: null,
        active: 1,
        percent: "99",
        entity_id: null,
      });
    },
    entityDlt(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminarla entidad (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.contractor_entities[i].id === null) {
              this.data.contractor_entities.splice(i, 1);
            } else {
              this.data.contractor_entities[i].active = false;
            }
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("provider_groups", this.login.token).then((response) => {
      this.provider_groups = response;
      this.provider_groups_loading = false;
    });

    catalog("doctor_groups", this.login.token).then((response) => {
      this.doctor_groups = response;
      this.doctor_groups_loading = false;
    });

    index("contractors/brands", this.login.token, []).then((response) => {
      this.contractor_brands = response.data;
      this.contractor_brands.push({
        id: null,
        contractor_brand: "NO APLICA",
      });
      this.contractor_brands_loading = false;
    });

    catalog("fiscal_regimes", this.login.token).then((res) => {
      this.fiscal_regimes = res;
      this.fiscal_regimes_ldg = false;
    });

    catalog("fiscal_uses", this.login.token).then((res) => {
      this.fiscal_uses = res;
      this.fiscal_uses_ldg = false;
    });

    catalog("fiscal_method_payments", this.login.token).then((res) => {
      this.fiscal_method_payments = res;
      this.fiscal_method_payments_ldg = false;
    });

    catalog("contractor_insured_formats", this.login.token).then((res) => {
      this.contractor_insured_formats = res;
      this.contractor_insured_formats_ldg = false;
    });

    if (!this.store_mode) {
      this.loading = true;

      show("contractors", this.login.token, this.id).then((response) => {
        this.data = response;
        if (this.data.letters === null) this.data.letters = [];
        this.loading = false;
      });
    }

    index("provider/types", this.login.token, []).then((response) => {
      this.provider_types = response.data;
      this.provider_types_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=entities&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.entities = resp.data.data;
      this.entities_ldg = false;
    });

    this.rules = rules();
  },
};
</script>